<template>
  <div id="sucursales" class="contenedor">
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">LISTA DE SUCURSALES</v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerSucursales()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addBranch" id="nuevoSucursal">
              <button class="breakSearchE botonAmarillo">
                Alta de Sucursal
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="branches"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Sucursales por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encont5raron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombreSucursal }}</td>

                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon
                      color="red"
                      small
                      class="mr-6"
                      @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Sucursal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Empresa</v-card-title
        >
        <v-card-text style="height: 300px" id="info">
          <div class="form-group mt-5">
            <label>ID de Sucursal:</label>
            {{ branchSelect.id }}
          </div>
          <div class="form-group">
            <label>Nombre:</label>
            {{ branchSelect.nombreSucursal }}
          </div>
          <div class="form-group">
            <label>Número Telefónico:</label>
            {{ branchSelect.telefono }}
          </div>
          <div class="form-group">
            <label>País:</label>
            {{ branchSelect.pais }}
          </div>
          <div class="form-group">
            <label>C.P. :</label>
            {{ branchSelect.codigoPostal }}
          </div>
          <div class="form-group">
            <label>Estado:</label>
            {{ state }}
          </div>
          <div class="form-group">
            <label>Municipio:</label>
            {{ municipality }}
          </div>
          <div class="form-group">
            <label>Colonia:</label>
            {{ branchSelect.asentamientoId }}
          </div>
          <div class="form-group">
            <label>Calle:</label>
            {{ branchSelect.calle }}
          </div>
          <div class="form-group">
            <label>N° Exterior:</label>
            {{ branchSelect.numeroExterior }}
          </div>
          <div class="form-group">
            <label>N° Interior:</label>
            {{ branchSelect.numeroInterior }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Sesion,
  },
  data() {
    return {
      search: "",
      dialog: false,
      expiration: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
        },
        {
          text: "Sucursal",
          align: "center",
          value: "nombreSucursal",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      state: "",
      municipality: "",
      address: [],
      branches: [],
      branchSelect: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      empresa: 0,
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.empresa = 0;
      this.nombreArea = "";
      this.nombreCorto = "";
      this.nivel = "";
      this.vacio = false;
    },
    validacion: function () {
      this.errors = [];
      if (this.empresa == "" || this.empresa == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.nombreArea == "" || this.nombreArea == " ") {
        this.errors.push("Se debe especificar el nombre del área.");
      }
      if (this.nombreCorto == "" || this.nombreCorto == " ") {
        this.errors.push("Se debe especificar el nombre corto del área.");
      }
      if (this.nivel == "" || this.nivel == 0) {
        this.errors.push("Se debe especificar el nivel jerárquico.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      console.log(this.id);
      console.log("El id para la api de editar area es " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/areas/" + this.id,
          {
            Id: this.id,
            EmpresaId: this.empresa,
            NombreArea: this.nombreArea,
            NombreAreaCorto: this.nombreCorto,
            NivelJerarquico: parseInt(this.nivel),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.empresa = 0;
          this.nombreArea = "";
          this.nombreCorto = "";
          this.nivel = 0;
          this.respuesta = "El area fue actualizada con éxito.";
          this.obtenerEmpresa();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.empresa = 0;
            this.nombreArea = "";
            this.nombreCorto = "";
            this.nivel = 0;
            this.respuesta = "El area no fue actualizada con éxito.";
            this.obtenerEmpresa();
            this.confirmation = true;
          }
        });
    },
    editItem(item) {
      console.log("Ingreso a editar sucursal" + item.id);
      localStorage.sucursalId = item.id;
      this.$router.push("/updateBranch");
    },

    temporal(item) {
      console.log(item);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la Sucursal " + item.nombreSucursal + " ?";
      this.advice = true;
    },

    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/sucursales/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerSucursales();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },

    viewItem(item) {
      console.log("Ingreso a detalle" + item.id);
      this.branchSelect = item;
      this.consultaDireccion();
      this.dialog = true;
    },
    consultaDireccion() {
      if (this.branchSelect.codigoPostal.length == 5) {
        this.suburbs = [];
        this.show = true;
        axios
          .get(Server + "/direcciones/" + this.branchSelect.codigoPostal, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            //console.log(response);
            this.address = response.data;
            this.state = this.address[0].estado;
            this.municipality = this.address[0].municipio;
            this.address.forEach((value, index) => {
              if (value.asentamientoId == this.branchSelect.asentamientoId) {
                this.branchSelect.asentamientoId = value.colonia;
              }
            });
            //console.log(suburbs);
            this.show = false;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        //console.log("Menor a 5");
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("Resultado de empresas");
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerSucursales() {
      /* console.log("el id de empresa es" + this.enterprise);
      return (localStorage.idEmpresa = this.enterprise); */
      this.branches = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("resultado de todas las areas");
          //console.log(response);
          this.branches = response.data;
          /* var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.branches.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico
              });
            }
          }); */
          //console.log("resultado de areas por empresa");
          // console.log(this.branches);
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    //console.log("creado lista de area" + localStorage.idEmpresa);
    this.listar();
    this.obtenerSucursales();
  },
};
</script>